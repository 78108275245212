import { library, dom, config } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleLeft,
  faAnglesLeft,
  faAngleRight,
  faAnglesRight,
  faAngleDown,
  faEnvelopeOpen,
  faProjectDiagram,
  faMousePointer,
  faClipboardCheck,
  faSlidersH,
  faMoneyCheckDollar,
  faShop,
  faBuilding,
  faCircleCheck,
  faEnvelope,
  faCaretRight,
  faCaretDown,
  faCaretUp,
  faMagnifyingGlass,
  faCheck,
  faCheckDouble,
  faXmark,
  faCopy,
  faRotate,
  faCog,
  faCircle,
  faAt,
  faLock,
  faEgg,
  faFaceDizzy,
  faCircleNotch,
  faArrowRight,
  faInfoCircle,
  faRocket,
  faRobot,
  faUserNinja,
  faUserSecret,
  faCalendarDays,
  faHouse,
} from '@fortawesome/free-solid-svg-icons'

import {
  faGithub,
  faInstagram,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons'

config.mutateApproach = 'sync'
library.add(
  // Solid
  faAngleLeft,
  faAnglesLeft,
  faAngleRight,
  faAnglesRight,
  faAngleDown,
  faEnvelopeOpen,
  faProjectDiagram,
  faMousePointer,
  faClipboardCheck,
  faSlidersH,
  faMoneyCheckDollar,
  faShop,
  faBuilding,
  faCircleCheck,
  faEnvelope,
  faCaretRight,
  faCaretDown,
  faCaretUp,
  faMagnifyingGlass,
  faCheck,
  faCheckDouble,
  faXmark,
  faCopy,
  faRotate,
  faCog,
  faCircle,
  faAt,
  faLock,
  faEgg,
  faFaceDizzy,
  faCircleNotch,
  faArrowRight,
  faInfoCircle,
  faRocket,
  faRobot,
  faUserNinja,
  faUserSecret,
  faCalendarDays,
  faHouse,

  // Regular

  // brands
  faGithub,
  faInstagram,
  faWhatsapp
)
dom.watch()
